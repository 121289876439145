<template>
  <!-- 企业登录 -->
  <div class="login-content">
    <div class="bg">
      <img src="../../../static/img/login-bg.png" alt="" />
    </div>
    <div class="content flex">
      <div class="left">
        <img src="../../../static/img/text.png" alt="" />
      </div>
      <div class="right">
        <!-- 内容头部 -->
        <div class="title ub">
          <div
            :class="{
              'ch-login': tabLogin == 'phone' || tabLogin == 'passWord',
            }"
            class="title-label ub-f1 pointer"
            @click="tabClick('passWord')"
          >
            手机号登录
          </div>
          <div class="line" />
          <div
            :class="{ 'ch-login': tabLogin == 'mail' }"
            class="title-label ub-f1 pointer"
            @click="tabClick('mail')"
          >
            邮箱登录
          </div>
        </div>
        <!-- 密码登录 -->
        <div class="ipt" v-show="tabLogin == 'passWord'">
          <el-form
            ref="codePassRef"
            :model="codePassForm"
            :rules="codePassRule"
          >
            <el-form-item prop="userName">
              <el-input
                v-model="codePassForm.userName"
                placeholder="请输入账号"
              >
                <template slot="prefix">
                  <img src="../../../static/img/yonghutouxiang.png" alt="" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="passWord">
              <el-input
                v-model="codePassForm.passWord"
                type="password"
                placeholder="请输入密码"
                @keyup.enter.native="submitLogin"
              >
                <template slot="prefix">
                  <img src="../../../static/img/tubiao203.png" alt="" />
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 手机号验证登录 -->
        <div v-show="tabLogin == 'phone'" class="ipt">
          <el-form
            ref="codePhoneRef"
            :model="codePhoneForm"
            :rules="codePhoneRule"
            label-width="0px"
          >
            <el-form-item prop="userName">
              <el-input
                v-model="codePhoneForm.userName"
                placeholder="请输入手机号"
              >
                <template slot="prefix"
                  ><img src="../../../static/img/yonghutouxiang.png" alt=""
                /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="passWord">
              <el-input
                v-model="codePhoneForm.passWord"
                size="medium"
                placeholder="请输入验证码"
                @keyup.enter.native="submitLogin"
              >
                <template slot="prefix"
                  ><img src="../../../static/img/tubiao203.png" alt=""
                /></template>
                <template slot="suffix">
                  <a
                    v-show="sendAuthCode && !sendindCode"
                    class="code"
                    @click="getVerificationCode"
                    >获取验证码</a
                  >
                  <span v-show="!sendAuthCode" class="code code-disabled"
                    >重新发送({{ authTime }})</span
                  >
                  <span v-show="sendindCode" class="code code-disabled"
                    >发送中...</span
                  >
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 邮箱验证登录 -->
        <div v-show="tabLogin == 'mail'" class="ipt">
          <el-form
            ref="codeMailRef"
            :model="codeMailForm"
            :rules="codeMailRule"
          >
            <el-form-item prop="userName">
              <el-input
                v-model="codeMailForm.userName"
                placeholder="请输入邮箱"
              >
                <template slot="prefix"
                  ><img src="../../../static/img/yonghutouxiang.png" alt=""
                /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="passWord">
              <el-input
                v-model="codeMailForm.passWord"
                type="password"
                placeholder="请输入密码"
                @keyup.enter.native="submitLogin"
              >
                <template slot="prefix"
                  ><img src="../../../static/img/tubiao203.png" alt=""
                /></template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="other-login">
          <el-button
            v-if="tabLogin == 'phone'"
            type="text"
            @click="tabClick('passWord')"
            >使用密码登录</el-button
          >
          <el-button
            v-else-if="tabLogin == 'passWord'"
            type="text"
            @click="tabClick('phone')"
            >使用手机验证码登陆</el-button
          >
        </div>
        <el-button
          :loading="loading"
          type="primary"
          style="width: 100%"
          @click="submitLogin"
          >登 录</el-button
        >
        <div class="form-links">
          <el-button type="text" @click="$router.push('/forgetpwd')"
            >忘记密码 ?</el-button
          >
        </div>
      </div>
    </div>
    <!-- 版权信息 -->
    <div class="copyRight">
      <p class="text">
        {{info.copyright}}
      </p>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import info from "@/assets/common.js"
const formMap = {
  phone: "codePhoneRef",
  passWord: "codePassRef",
  mail: "codeMailRef",
};
export default {
  name: "login-content",
  data() {
    // 自定义数据校验规则
    var validatePhonePage = (rule, value, callback) => {
      // 手机号格式 [暂时没写-_-] || 空
      // console.debug(value);
      // if (!validatePhone(value)) {
      //   callback(new Error("手机号码格式不正确"));
      // }
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else {
        callback();
      }
    };
    var validateMail = (rule, value, callback) => {
      // 邮箱格式 [暂时没写-_-] || 空
      var mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (!mailReg.test(value)) {
        callback(new Error("邮箱格式不正确"));
      }
      if (!value) {
        callback(new Error("邮箱不能为空"));
      } else {
        callback();
      }
    };
    // 从缓存中获取最后一次的登录方式
    const defLoginType = localStorage.getItem("defLoginType") || "passWord";
    return {
      loading: false,
      showTo: true,
      sendindCode: false, // 发送验证码
      sendAuthCode: true, // 发送验证码
      authTime: 0, // 验证码有效倒计时
      // 手机号获取验证码登录
      codePhoneForm: {
        userName: "",
        passWord: "",
        loginType: 2,
        verificationtype: "1",
      },
      codePhoneRule: {
        userName: [{ validator: validatePhonePage, trigger: "blur" }],
        passWord: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
      // 密码登录
      codePassForm: {
        userName: "",
        passWord: "",
        loginType: 1,
      },
      codePassRule: {
        userName: [{ validator: validatePhonePage, trigger: "blur" }],
        passWord: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },

      // 邮箱登录
      codeMailForm: {
        userName: "",
        passWord: "",
        loginType: 1,
      },
      codeMailRule: {
        userName: [{ validator: validateMail, trigger: "blur" }],
        passWord: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },

      // 邮箱、密码、手机 登录切换
      tabLogin: defLoginType,
      info,
    };
  },
  components: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     * @name 不同登录切换
     * @params tabName [String] : 切换的tab name
     */
    tabClick(tabName) {
      this.$refs[formMap[tabName]].clearValidate();
      this.$refs[formMap[tabName]].resetFields();
      this.tabLogin = tabName;
      // 首先应该置空所有提交绑定的数据 （看到的人可以自己加，需求没确定）
      if (tabName == "phone") {
        this.codePhoneForm.userName = this.codePassForm.userName;
      } else if (tabName == "passWord") {
        this.codePassForm.userName = this.codePhoneForm.userName;
      }
      localStorage.setItem("defLoginType", tabName);
    },
    /**
     * @name 登录提交
     * @params refName [String] : 数据校验绑定的ref name
     *         subForm [String] : 提交数据绑定的 name
     */
    submitLogin() {
      let refName, subForm;
      if (this.tabLogin == "phone") {
        refName = "codePhoneRef";
        subForm = "codePhoneForm";
      } else if (this.tabLogin == "passWord") {
        refName = "codePassRef";
        subForm = "codePassForm";
      } else if (this.tabLogin == "mail") {
        refName = "codeMailRef";
        subForm = "codeMailForm";
      }
      this.$refs[refName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("UserLogin", this[subForm])
            .then(() => {
              this.loading = false;
              if (this.$route.query.pages) {
                // 供货市场跳转
                if (this.$route.query.appType) {
                  if (this.$route.query.appType == 1) {
                    window.location.href =
                      decodeURIComponent(this.$route.query.pages) +
                      "?token=" +
                      "";
                    // getToken();
                  } else if (this.$route.query.appType == 2) {
                    window.location.href =
                      decodeURIComponent(this.$route.query.pages) +
                      "&token=" +
                      "";
                    // getToken();
                  } else if (this.$route.query.appType == 3) {
                    window.location.href =
                      decodeURIComponent(this.$route.query.pages) +
                      "?token=" +
                      "";
                    // getToken();
                  } else if (this.$route.query.appType == 4) {
                    window.location.href =
                      decodeURIComponent(this.$route.query.pages) +
                      "?token=" +
                      "";
                    // getToken();
                  }
                } else {
                  this.$router.push({ path: this.$route.query.pages });
                }
              } else {
                this.$router.push({ path: "/" });
              }
            })
            .catch(() => {
              this.loading = false;
            });
          // alert('登录成功')
        }
      });
    },

    /**
     * @name 获取验证码
     */
    getVerificationCode() {
      console.log(this.codePhoneForm);
      this.codePhoneForm.loginDomain = window.location.host;
      this.$refs["codePhoneRef"].validateField("userName", (err) => {
        if (err) {
          this.$message.error(err);
          return;
        }
        this.sendindCode = true;
        this.$store
          .dispatch("getVerificationCode", this.codePhoneForm)
          .then(() => {
            this.sendindCode = false;
            this.$router.push({ path: "/" });
            this.getAuthCode();
          })
          .catch(() => {
            this.sendindCode = false;
          });
      });
    },

    /**
     * 验证码有效倒计时
     *
     */
    getAuthCode: function () {
      this.sendAuthCode = false;
      this.authTime = 60;
      var auth_timetimer = setInterval(() => {
        this.authTime--;
        if (this.authTime <= 0) {
          this.sendAuthCode = true;
          clearInterval(auth_timetimer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-content {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #fff;
  /deep/ .el-form-item__label:before {
    content: "" !important;
  }
  /deep/ .el-checkbox__inner {
    background-color: #409eff;
    border-color: #409eff;
  }

  /deep/ .el-input__inner {
    height: 48px;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  /deep/ .el-input__prefix {
    color: #333333;
    text-align: center;
    width: 26px;
    margin: 0 10px;
  }
  /deep/ .el-input__suffix-inner {
    line-height: 48px;
  }

  /deep/ .el-button--primary {
    font-size: 24px;
    background: #106297;
    i {
      width: 24px;
    }
  }

  .el-form-item {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    .left {
      width: 790px;
      // flex-shrink: 0;
    }
    .right {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 400px;
      height: 400px;
      background: #fff;
      padding: 40px;
      border-radius: 8px;
      box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.1);
      margin-left: 46px;
      .title {
        width: 100%;
        display: flex;
        align-items: center;
        .title-label {
          font-size: 22px;
          height: 100%;
          line-height: 52px;
          text-align: center;
          color: #333333;
          flex: 1;
          cursor: pointer;
        }
        .ch-login {
          color: #176395 !important;
        }
        .line {
          height: 30px;
          width: 1px;
          background: rgba(237, 237, 237, 1);
        }
      }
      .ipt {
        margin-top: 44px;
        .code {
          cursor: pointer;
          width: 168px;
          padding: 4px 20px;
          color: #176395;
        }
        .code-disabled {
          cursor: not-allowed;
          color: gray;
        }
      }
    }
    .other-login {
      height: 36px;
      margin-bottom: 10px;
      .el-button {
        color: #176395;
      }
    }
    .form-links{
      display: flex;
      justify-content: flex-end;
      .el-button {
        color: #176395;
      }
    }
  }
  .copyRight {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.02);
    .text {
      font-size: 14px;
      color: #176395;
    }
  }
}
</style>
